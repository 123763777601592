import { AppBar, Toolbar, Typography } from "@material-ui/core";
import Logo from "../../images/sasakawalogo.png";

const MainNavbar = (props) => (
  <AppBar sx={{ background: "#4caf50" }} elevation={0} {...props}>
    <Toolbar sx={{ height: 64 }}>
      <>
        <img src={Logo} style={{ maxHeight: 60 }} />
        <Typography variant="h4" component="div" sx={{ ml: 2, flexGrow: 1 }}>
          Maed Farm Suite Admin Portal
        </Typography>
      </>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
