import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

export default ({element}) => {
    let location = useLocation();
    const { authState } = useAuth()
    const { isLoggedIn } = authState    
    
    return (isLoggedIn?element:<Navigate to="/auth/login" state={{ from: location }} />);
        
}