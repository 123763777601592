import React from "react";
import { Box, Container, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  progressBarStyle: {
    marginTop: 100,
    marginBottom: "2rem",
    textAlign: "center",
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 5 }}>
            <div className={classes.progressBarStyle}>
              <CircularProgress />
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
