import {
    NavLink as RouterLink,
    matchPath,
    useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ListItem, Collapse, List } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const NavItem = ({
    href,
    icon: Icon,
    title,
    onClick,
    open,
    multi,
    subList,
    ...rest
}) => {
    const location = useLocation();

    const active =
        href && location
            ? !!matchPath(
                  {
                      path: href,
                      end: false,
                  },
                  location.pathname
              )
            : false;
    const isActive = (href) => {
        return href && location
            ? !!matchPath(
                  {
                      path: href,
                      end: false,
                  },
                  location.pathname
              )
            : false;
    };

    return multi ? (
        <>
            <ListItem
                disableGutters
                sx={{
                    display: "flex",
                    py: 0,
                }}
                {...rest}
            >
                <Button
                    sx={{
                        color: "text.secondary",
                        fontWeight: "medium",
                        justifyContent: "flex-start",
                        letterSpacing: 0,
                        py: 1.25,
                        textTransform: "none",
                        width: "100%",
                        ...(active && {
                            color: "primary.link",
                        }),
                        "& svg": {
                            mr: 1,
                        },
                    }}
                    onClick={onClick}
                >
                    {Icon && <Icon size="20" />}
                    <span>{title}</span>
                    
                        {open ? <ExpandLess sx={{pl: 1}} /> : <ExpandMore sx={{pl: 1}}/>}
                    
                </Button>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {subList.map((item) => (
                        <ListItem
                            disableGutters
                            sx={{
                                display: "flex",
                                py: 0,
                                pl: 4,
                            }}
                            {...rest}
                        >
                            <Button
                                component={RouterLink}
                                sx={{
                                    color: "text.secondary",
                                    fontWeight: "medium",
                                    justifyContent: "flex-start",
                                    letterSpacing: 0,
                                    py: 1.25,
                                    textTransform: "none",
                                    width: "100%",
                                    ...(isActive(item.href) && {
                                        color: "primary.link",
                                    }),
                                    "& svg": {
                                        mr: 1,
                                    },
                                }}
                                to={item.href}
                            >
                                {item.icon && <item.icon size="20" />}
                                <span>{item.title}</span>
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    ) : (
        <>
            <ListItem
                disableGutters
                sx={{
                    display: "flex",
                    py: 0,
                }}
                {...rest}
            >
                <Button
                    component={RouterLink}
                    sx={{
                        color: "text.secondary",
                        fontWeight: "medium",
                        justifyContent: "flex-start",
                        letterSpacing: 0,
                        py: 1.25,
                        textTransform: "none",
                        width: "100%",
                        ...(active && {
                            color: "primary.link",
                        }),
                        "& svg": {
                            mr: 1,
                        },
                    }}
                    to={href}
                >
                    {Icon && <Icon size="20" />}
                    <span>{title}</span>
                </Button>
            </ListItem>
        </>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
    open: PropTypes.bool,
    multi: PropTypes.bool,
    subList: PropTypes.array,
};

export default NavItem;
