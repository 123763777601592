import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputIcon from "@material-ui/icons/Input";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/sasakawalogo.png";

const DashboardNavbar = ({ onMobileNavOpen, title, ...rest }) => {
  const navigate = useNavigate();
  const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    navigate("/auth/logout");
  };

  return (
    <AppBar
      sx={{ background: "#4caf50" }}
      color="primary"
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <>
          <img src={Logo} style={{ maxWidth: 60 }} />
          <Typography variant="h4" component="div" sx={{ ml: 2, flexGrow: 1 }}>
            Maed Farm Suite Admin Portal
          </Typography>
        </>
        <Box sx={{ flexGrow: 1 }} />
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onClickLogout}>Logout</MenuItem>
          </Menu>
        </div>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
