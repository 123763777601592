import { lazy } from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import AuthLayout from "./components/Auth/AuthLayout";
import PrivateRoute from "./components/PrivateRoute";
import SuspenseWrapper from "./components/SuspenseWrapper";

const Dashboard = lazy(() => import("./containers/dashboard/DashboardHome"));
const Users = lazy(() => import("./containers/users/UsersList"));
const Forms = lazy(() => import("./containers/forms/Forms"));
const Surveys = lazy(() => import("./containers/surveys/Surveys"));
const Trainings = lazy(() => import("./containers/Trainings"));
const Dictionary = lazy(() => import("./containers/dictionary/Dictionary"));
const Markets = lazy(() => import("./containers/markets/Markets"));
const DealerMarkets = lazy(() => import("./containers/markets/DealerMarket"));
const Settings = lazy(() => import("./containers/settings/Settings"));
const Items = lazy(() => import("./containers/items/Items"));
const Zones = lazy(() => import("./containers/zones/Zones"));
const Woredas = lazy(() => import("./containers/woredas/Woredas"));
const Towns = lazy(() => import("./containers/towns/Towns"));
const ServiceProviders = lazy(() =>
  import("./containers/serviceProviders/ServiceProviders")
);
const ServiceTypes = lazy(() =>
  import("./containers/serviceType/ServiceTypes")
);
const SasakawaTips = lazy(() =>
  import("./containers/SasakawaTips/SasakawaTips")
);
const UnitConversion = lazy(() =>
  import("./containers/unitConversion/UnitConversion")
);
const Regions = lazy(() => import("./containers/regions/Regions"));
const Kebeles = lazy(() => import("./containers/Kebele/Kebeles"));
const Roles = lazy(() => import("./containers/roles/Roles"));
const SubItems = lazy(() => import("./containers/subItems/SubItems"));
const FormsList = lazy(() => import("./components/mfsForums/formsList"));
const Feedbacks = lazy(() => import("./containers/feedbacks/feedbacks"));

const Login = lazy(() => import("./containers/auth/Login"));
const Register = lazy(() => import("./containers/auth/Register"));
const Logout = lazy(() => import("./containers/auth/Logout"));
const NotFound = lazy(() => import("./components/NotFound"));

/* import Dashboard from "./containers/dashboard/DashboardHome";
import Users from "./containers/users/UsersList";
import Forms from "./containers/forms/Forms";
import Surveys from "./containers/surveys/Surveys";
import Trainings from "./containers/Trainings";
import Dictionary from "./containers/dictionary/Dictionary";
import Markets from "./containers/markets/Markets";
import DealerMarkets from "./containers/markets/DealerMarket";
import Settings from "./containers/settings/Settings";
import Items from "./containers/items/Items";
import Zones from "./containers/zones/Zones";
import Woredas from "./containers/woredas/Woredas";
import Towns from "./containers/towns/Towns";
import ServiceProviders from "./containers/serviceProviders/ServiceProviders";
import ServiceTypes from "./containers/serviceType/ServiceTypes";
import SasakawaTips from "./containers/SasakawaTips/SasakawaTips";
import UnitConversion from "./containers/unitConversion/UnitConversion";
import Regions from "./containers/regions/Regions";
import Kebeles from "./containers/Kebele/Kebeles";
import Roles from "./containers/roles/Roles";
import SubItems from "./containers/subItems/SubItems";
import FormsList from "./components/mfsForums/formsList";

import Login from "./containers/auth/Login";
import Register from "./containers/auth/Register";
import Logout from "./containers/auth/Logout";
import NotFound from "./components/NotFound"; */

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Dashboard />} />} />
        ),
      },
      {
        path: "users-list",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Users />} />} />
        ),
      },
      {
        path: "forms",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Forms />} />} />
        ),
      },
      {
        path: "surveys",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Surveys />} />} />
        ),
      },
      {
        path: "mfsFarm",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<FormsList />} />} />
        ),
      },
      // ForumsTable
      {
        path: "trainings",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Trainings />} />} />
        ),
      },
      {
        path: "dictionary",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<Dictionary />} />}
          />
        ),
      },
      {
        path: "markets",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Markets />} />} />
        ),
      },
      {
        path: "cooperative-unions",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<DealerMarkets />} />}
          />
        ),
      },
      {
        path: "settings",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Settings />} />} />
        ),
      },
      {
        path: "items",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Items />} />} />
        ),
      },
      {
        path: "regions",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Regions />} />} />
        ),
      },
      {
        path: "zones",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Zones />} />} />
        ),
      },
      {
        path: "woredas",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Woredas />} />} />
        ),
      },
      {
        path: "towns",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Towns />} />} />
        ),
      },
      {
        path: "kebeles",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Kebeles />} />} />
        ),
      },
      {
        path: "service_providers",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<ServiceProviders />} />}
          />
        ),
      },
      {
        path: "service_type",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<ServiceTypes />} />}
          />
        ),
      },
      {
        path: "sasakawa_tips",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<SasakawaTips />} />}
          />
        ),
      },
      {
        path: "unit_conversion",
        element: (
          <SuspenseWrapper
            element={<PrivateRoute element={<UnitConversion />} />}
          />
        ),
      },
      {
        path: "roles",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Roles />} />} />
        ),
      },
      {
        path: "varieties",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<SubItems />} />} />
        ),
      },
      {
        path: "feedbacks",
        element: (
          <SuspenseWrapper element={<PrivateRoute element={<Feedbacks />} />} />
        ),
      },
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { path: "login", element: <SuspenseWrapper element={<Login />} /> },
      { path: "register", element: <SuspenseWrapper element={<Register />} /> },
      { path: "logout", element: <SuspenseWrapper element={<Logout />} /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "404", element: <SuspenseWrapper element={<NotFound />} /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
