import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./SideNav";

import {
    useDashboard,
    DashboardProvider,
} from "../../providers/DashboardProvider";
import { useAuth } from "../../providers/AuthProvider";
import { extractUserRoles } from "../../services/userService";
import { UserRolesMap } from "../../components/User/UserListComponent";
import AuthService from "../../services/authService";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
        paddingLeft: 256,
    },
}));

const DashboardLayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
});

const DashboardLayoutWrapperMain = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const { state } = useDashboard();

    const { authState } = useAuth();
    const fullName = authState.isLoggedIn
        ? (AuthService.get_user().first_name
              ? AuthService.get_user().first_name
              : " - ") +
          " " +
          AuthService.get_user().last_name
            ? AuthService.get_user().last_name
            : "-"
        : "";
    const roles = authState.isLoggedIn ? AuthService.get_user().roles : [];
    const role = roles.length
        ? roles.map((role) => UserRolesMap[role]).join(", ")
        : "";

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar
                onMobileNavOpen={() => setMobileNavOpen(true)}
                title={state.title}
            />
            <DashboardSidebar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
                title={state.title}
                role={role}
                roles={roles}
                fullName={fullName}
                active="home"
                hasImage={state.hasImage}
                image={state.image}
                nomargin={true}
            />
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet />
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

const DashboardLayout = () => {
    return (
        <DashboardProvider>
            <DashboardLayoutWrapperMain />
        </DashboardProvider>
    );
};

export default DashboardLayout;
