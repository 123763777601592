import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
} from "@material-ui/core";
import {
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    Users as UsersIcon,
    File,
    Volume2,
    Edit,
    List as ListIcon,
    Wind,
} from "react-feather";
import { green } from "@material-ui/core/colors";
import NavItem from "./NavItem";
import getInitials from "./getInitials";

const DashboardSidebar = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const items = [
        {
            href: "/dashboard",
            icon: BarChartIcon,
            title: "Dashboard",
        },
        ...(props.roles.includes("admin")
            ? [
                {
                    href: "/forms",
                    icon: File,
                    title: "Forms",
                },
            ]
            : []),
        ...(props.roles.includes("admin") || props.roles.includes("ea")
            ? [
                {
                    href: "/surveys",
                    icon: Edit,
                    title: "Surveys",
                },
            ]
            : []),
        ...(props.roles.includes("admin") || props.roles.includes("ea")
            ? [
                {
                    href: "/mfsFarm",
                    icon: Edit,
                    title: "MFS Farms",
                },
            ]
            : []),
        ...(props.roles.includes("admin") ||
            props.roles.includes("content-manager")
            ? [
                {
                    href: "/trainings",
                    icon: Volume2,
                    title: "Trainings",
                },
            ]
            : []),
        ...(props.roles.includes("admin")
            ? [
                {
                    title: "Users and Roles",
                    onClick: () => setOpen2(!open2),
                    open: open2,
                    multi: true,
                    icon: UsersIcon,
                    subList: [
                        {
                            href: "/users-list",
                            icon: UsersIcon,
                            title: "Users",
                        },
                        {
                            href: "/roles",
                            icon: ListIcon,
                            title: "Roles",
                        },
                    ],
                },
            ]
            : []),
        ...(props.roles.includes("admin")
            ? [
                {
                    href: "/dictionary",
                    icon: LockIcon,
                    title: "Dictionary",
                },
            ]
            : []),
        ...(props.roles.includes("admin") || props.roles.includes("ea")
            ? [
                {
                    href: "/markets",
                    icon: ShoppingBagIcon,
                    title: "Markets",
                },
                {
                    href: "/cooperative-unions",
                    icon: ShoppingBagIcon,
                    title: "Cooperative Unions",
                },
            ]
            : []),

        ...(props.roles.includes("admin") /*  ||  (props.roles.includes("ea") */
            ? [
                {
                    href: "/items",
                    icon: ListIcon,
                    title: "Crop/Careals",
                },
                {
                    href: "/varieties",
                    icon: ListIcon,
                    title: "Varieties",
                },
            ]
            : []),
        ...(props.roles.includes("admin")
            ? [
                {
                    title: "Intervention areas",
                    onClick: () => setOpen(!open),
                    open: open,
                    multi: true,
                    icon: ListIcon,
                    subList: [
                        {
                            href: "/regions",
                            icon: ListIcon,
                            title: "Regions",
                        },
                        {
                            href: "/zones",
                            icon: ListIcon,
                            title: "Zones",
                        },
                        {
                            href: "/woredas",
                            icon: ListIcon,
                            title: "Woredas",
                        },
                        {
                            href: "/kebeles",
                            icon: ListIcon,
                            title: "Kebeles",
                        },
                        {
                            href: "/towns",
                            icon: ListIcon,
                            title: "Towns",
                        },
                    ],
                },

                {
                    href: "/service_providers",
                    icon: ListIcon,
                    title: "Service Providers",
                },
                {
                    href: "/service_type",
                    icon: ListIcon,
                    title: "Service Type",
                },
            ]
            : []),
        ...(props.roles.includes("admin") /*  || props.roles.includes("ea") */
            ? [
                {
                    href: "/sasakawa_tips",
                    icon: Wind,
                    title: "Sasakawa Tips",
                },
                {
                    href: "/unit_conversion",
                    icon: ListIcon,
                    title: "Unit Conversion",
                },
            ]
            : []),
        ...(props.roles.includes("admin")
            ? [
                {
                    href: "/feedbacks",
                    icon: SettingsIcon,
                    title: "Feedbacks",
                },
            ]
            : []),
        ...(props.roles.includes("admin")
            ? [
                {
                    href: "/settings",
                    icon: SettingsIcon,
                    title: "Settings",
                },
            ]
            : []),
    ];
    console.log("items", items);
    useEffect(() => {
        if (props.openMobile && props.onMobileClose) {
            props.onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                }}
            >
                <Avatar
                    component={RouterLink}
                    /* src="/static/images/avatars/avatar_12.png" */
                    sx={{
                        cursor: "pointer",
                        width: 72,
                        height: 72,
                        bgcolor: green[500],
                    }}
                    to="/dashboard"
                >
                    {/* {getInitials(props.fullName)} */}
                </Avatar>
                <Typography sx={{ pt: 1 }} color="textPrimary" variant="h5">
                    {props.fullName}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                    {props.role}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            onClick={item.onClick}
                            open={item.open}
                            multi={item.multi}
                            subList={item.subList}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box
                sx={{
                    backgroundColor: "background.default",
                    m: 2,
                    p: 2,
                }}
            >
                <Typography align="center" gutterBottom variant="h4">
                    Need log out?
                </Typography>
                <Typography align="center" variant="body2">
                    Maed Farm Suite Admin Portal
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        pt: 2,
                    }}
                >
                    <Button
                        color="success"
                        component="a"
                        onClick={() => navigate("/auth/logout")}
                        variant="contained"
                    >
                        Sign out
                    </Button>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={props.onMobileClose}
                    open={props.openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256,
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: "calc(100% - 64px)",
                        },
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false,
};

export default DashboardSidebar;
