
export const LOGIN_REQUEST = 'auth/login/request'
export const LOGIN_REQUEST_ERROR = 'auth/login/error'
export const LOGIN_REQUEST_SUCCESS = 'auth/login/success'

export const LOGOUT_REQUEST = 'auth/logout/request'
export const LOGOUT_REQUEST_ERROR = 'auth/logout/error'
export const LOGOUT_REQUEST_SUCCESS = 'auth/logout/success'

export const SAVE_TOKEN = 'auth/savetoken'
export const GET_TOKEN = 'auth/gettoken'
export const DELETE_TOKEN = 'auth/deletetoken'

export const REGISTER_REQUEST = 'auth/register/request'
export const REGISTER_REQUEST_ERROR = 'auth/register/error'
export const REGISTER_REQUEST_SUCCESS = 'auth/register/success'

export const CHECK_IS_LOGGEDIN = 'auth/isloggedin'
export const CHECK_IS_LOGGEDIN_SUCCESS = 'auth/isloggedin/success'
export const CHECK_IS_LOGGEDIN_ERROR = 'auth/isloggedin/success'

export const UPLOAD_FILE = 'global/upload'
export const UPLOAD_FILE_ERROR = 'global/upload/error'
export const UPLOAD_FILE_SUCCESS = 'global/upload/success'
export const SET_PAGE_TITLE = 'global/set-page-title'

export const TOGGLE_HAS_IMAGE = 'dashboard/toggle-has-image'
export const CHANGE_TITLE = 'dashboard/change-title'