import { CHANGE_TITLE, TOGGLE_HAS_IMAGE } from "../constants/actionContants";

export const initialState = {
  loading: false,
  hasImage: false,
  title: "",
  image: null
}

export default (state, action) => {
  switch (action.type) {
    case CHANGE_TITLE:
      return {
        ...state,
        title: action.title
      }
    case TOGGLE_HAS_IMAGE:
      return {
        ...state,
        hasImage: action.hasImage,
        image: action.image
      }
    default:
      return state
    }
}