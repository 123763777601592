import "react-perfect-scrollbar/dist/css/styles.css";
import { useRoutes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import routes from "./routes";

import { createBrowserHistory } from "history";
import { AppProvider } from "./providers/AppPrivider";
import { HistoryProvider } from "./providers/HistoryProvider";
import { AuthProvider } from "./providers/AuthProvider";

const history = createBrowserHistory();

const App = () => {
    const content = useRoutes(routes);
    return (
        <AppProvider>
            <HistoryProvider history={history}>
                <AuthProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <GlobalStyles />
                            {content}
                        </ThemeProvider>
                    </StyledEngineProvider>
                </AuthProvider>
            </HistoryProvider>
        </AppProvider>
    );
};

export default App;
